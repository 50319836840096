import React, { useState, useEffect } from 'react'

import { Work, Education, Skills, Projects, Cron } from '../components'
import { BlurFade } from '../ui';

import Markdown from 'react-markdown'

const BLUR_FADE_DELAY = 0.04;

export default function Inicio({inicio}) {

    if (inicio.name === "" || inicio.name === undefined) {
        return (
            <></>
        )
    }


    return (
        <div className='max-w-2xl mx-auto py-12 sm:py-24 px-6 flex flex-col min-h-[100dvh] space-y-10'>
            {/*<hr className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-[60px]"/>*/}
            {

                <BlurFade delay={BLUR_FADE_DELAY}>
                    <section id="hero">
                        <div className="mx-auto w-full max-w-2xl space-y-8 text-black dark:text-white">
                            <div className="gap-2 flex justify-between">
                                <div className="flex-col flex flex-1 space-y-1.5">
                                    <div className="flex">
                                        <h1 className="inline-block text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none" style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-8px) translateZ(0px)" }}>{inicio.title}</h1>
                                    </div>
                                    <div className="flex">
                                        <span className="inline-block max-w-[600px] md:text-xl" style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-8px) translateZ(0px)" }}>{inicio.subtitle}</span>
                                    </div>
                                </div>
                                <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-8px) translateZ(0px)" }}>
                                    <span className="relative flex shrink-0 overflow-hidden rounded-full size-28 border">
                                        <img className="object-cover h-full w-full" alt={inicio.name} src={inicio.image} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                </BlurFade>

            }

            {

                <BlurFade delay={BLUR_FADE_DELAY * 2}>
                    <section id="about">
                        <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-8px) translateZ(0px)" }}>
                            <h2 className="text-xl font-bold">{inicio.about != "" ? "About" : ""}</h2>
                        </div>
                        <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-8px) translateZ(0px)" }}>
                            <div className="prose max-w-full text-pretty font-sans text-sm text-subtitle dark:prose-invert markdown">
                                <Markdown>{inicio.about}</Markdown>
                            </div>
                        </div>
                    </section>
                </BlurFade>

            }


            {

                /*

                inicio.estructura.sections.map((section, i) => {
                    return (
                        <BlurFade delay={BLUR_FADE_DELAY * (i + 2)}>
                            {(() => {
                                switch (section.content) {
                                    case 'education':
                                        return (<Education education={inicio.education ?? []} title={section.title} />)
                                    case 'work':
                                        return (<Work work={inicio.work ?? []} title={section.title} />)
                                    case 'skills':
                                        return (<Skills skills={inicio.skills ?? []} title={section.title} />)
                                    case 'projects':
                                        return (<Projects projects={inicio.projects ?? []} title={section.title} subtitle={section.subtitle} />)
                                    case 'cron':
                                        return (<Cron cron={inicio.cron ?? []} title={section.title} subtitle={section.subtitle} />)
                                    default:
                                        return (
                                            <></>
                                        )
                                }
                            })()}
                        </BlurFade>
                    )
                })

                */

            }

            {
                (inicio.trabajos ?? [].length > 0) &&
                <BlurFade delay={BLUR_FADE_DELAY * 3}>
                    <Work work={inicio.trabajos} title={"Work Experience"} />
                </BlurFade>
            }
            {
                (inicio.educacion ?? [].length > 0) &&
                <BlurFade delay={BLUR_FADE_DELAY * 4}>
                    <Education education={inicio.educacion ?? []} title={"Education"} />
                </BlurFade>
            }

            {
                (inicio.skills ?? [].length > 0) &&
                <BlurFade delay={BLUR_FADE_DELAY * 5}>
                    <Skills skills={inicio.skills ?? []} title={"Skills"} />
                </BlurFade>
            }
            {

                (inicio.proyectos ?? [].length > 0) &&
                <BlurFade delay={BLUR_FADE_DELAY * 6}>
                    <Projects projects={inicio.proyectos} title={"Proyectos"} subtitle={"He trabajado en una variedad de proyectos, desde sitios web sencillos hasta aplicaciones web complejas. Estos son algunos de mis favoritos."} href={{icono: "🛠️", texto: "Ver todos los proyectos", url: "https://pabloeguilaz.dev/creating"}} />
                </BlurFade>
            }

            {
                (inicio.cron ?? [].length > 0) &&
                <BlurFade delay={BLUR_FADE_DELAY * 7}>
                    <Cron cron={inicio.cron} title={"Me gusta construir cosas"} subtitle={"Personas de todo el país se reunían y construían cosas increíbles en dos o tres días. Fue revelador ver las infinitas posibilidades que un grupo de personas motivadas y apasionadas podía hacer realidad."} href={{icono: "🧑‍💻", texto: "Ver más", url: "https://pabloeguilaz.dev/notes"}} />
                </BlurFade>
            }
            
        </div>
    )
}



