import { isVisible } from '@testing-library/user-event/dist/utils'
import React, { useState, useEffect, useRef } from 'react'
import Markdown from 'react-markdown';

export default function Work({work, title}) {

    return (
        <>
            <section id="work">
                <div className="flex min-h-0 flex-col gap-y-3 text-black dark:text-white">
                    <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-6px) translateZ(0px)" }}>
                        <h2 className="text-xl font-bold">{title}</h2>
                    </div>
                    {
                        work.map((work, i) => {
                            return (
                                <WorkDetail work={work} key={i} />
                            )
                        })
                    }
                </div>
            </section>
        </>
    )
}



function WorkDetail({ work, i }) {
    const [isDetailVisible, setDetailVisible] = useState(false);
    const detailRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState('0px'); // altura inicial

    function viewWorkDetail() {
        setDetailVisible(prev => !prev);
    }

    useEffect(() => {
        if (detailRef.current) {
            setMaxHeight(isDetailVisible ? `${detailRef.current.scrollHeight}px` : '0px');
        }
    }, [isDetailVisible]);

    return (
        <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-6px) translateZ(0px)" }} key={i}>
            <div className="block cursor-pointer" onClick={viewWorkDetail}>
                <div className="rounded-lg text-card-foreground flex">
                    <div className="flex-none">
                        <span className="relative flex shrink-0 overflow-hidden rounded-full border size-12 m-auto">
                            <img className="aspect-square h-full w-full object-contain" alt={work.empresa} src={work.imagen} />
                        </span>
                    </div>
                    <div className="flex-grow ml-4 items-center flex-col group">
                        <div className="flex flex-col">
                            <div className="flex items-center justify-between gap-x-2 text-base">
                                <h3 className="inline-flex items-center justify-center font-semibold leading-none text-xs sm:text-sm">
                                    {work.empresa}
                                    {
                                        work.tags && (
                                            <span class="inline-flex gap-x-1 pl-1">
                                                {
                                                    work.tags.map((tag, i) => {
                                                        return (
                                                            <div key={i} class="inline-flex items-center rounded-md border px-2.5 py-0.5 font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-box dark:bg-box_dark text-secondary-foreground hover:bg-secondary/80 align-middle text-xs">{tag}</div>
                                                        )
                                                    })
                                                }
                                            </span>
                                        )
                                    }
                                    <span className="inline-flex gap-x-1"></span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={isDetailVisible ? "lucide lucide-chevron-right size-4 translate-x-0 transform opacity-0 transition-all duration-300 ease-out group-hover:translate-x-1 group-hover:opacity-100 rotate-90" : "lucide lucide-chevron-right size-4 translate-x-0 transform opacity-0 transition-all duration-300 ease-out group-hover:translate-x-1 group-hover:opacity-100 rotate-0"}>
                                        <path d="m9 18 6-6-6-6"></path>
                                    </svg>
                                </h3>
                                <div className="text-xs sm:text-sm tabular-nums text-subtitle text-right"> {work.desde} - {work.hasta}</div>
                            </div>
                            <div className="font-sans text-xs"> {work.puesto}</div>
                        </div>
                        <div
                            ref={detailRef}
                            style={{
                                maxHeight,
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out' // animación de transición
                            }}
                            className="mt-2 text-xs sm:text-sm markdown"
                        >
                            <Markdown>{work.descripcion}</Markdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
