import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useParams } from 'react-router-dom';

import { Inicio, PageNotFound, Print } from "./pages";
import { Dock } from "./components";


const requestOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json"
  }
};

function App() {

  const [inicio, setInicio] = useState({ "url": "", "name": "", "title": "", "subtitle": "", "about": "", "image": "", "seo": { "meta": { "title": "", "description": "", "keywords": "", "author": "" }, "open_graph": { "type": "", "url": "", "title": "", "description": "", "image": "" }, "twitter": { "card": "", "url": "", "title": "", "description": "", "image": "" } }, educacion: [], trabajos: [], skills: [], proyectos: {now: [], past: []}, cron: [] });

  useEffect(() => {

    const fetchData = async (endpoint) => {
      try {
        const response = await fetch(process.env.REACT_APP_PORTFOLIO_JSON_URL + endpoint, requestOptions);
        const data = await response.json();

        return data;
      } catch (error) {
        console.error('Error fetching work data:', error);
      }
    };

    fetchData('').then(data => {
      setInicio(data);
    })

  }, [])

  useEffect(() => {
    document.title = inicio.name;
    // add meta tags
    
  }, [inicio])

  useEffect(() => {
    if (localStorage.getItem('theme') === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    function detectDarkMode() {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.classList.add('dark')
        // Aquí puedes aplicar cambios, como cambiar el tema de la página
      } else {
        document.documentElement.classList.remove('dark')
      }
    }

    // Detectar modo oscuro al cargar la página
    //detectDarkMode();

    // Detectar cambios en la preferencia del usuario
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      if (event.matches) {
        document.documentElement.classList.add('dark')
        // Aquí puedes aplicar cambios, como cambiar el tema de la página
      } else {
        document.documentElement.classList.remove('dark')
      }
    });

  }, [])

  return (
    <>
      {/* Canonical */}
      <link rel="canonical" href={process.env.REACT_APP_URL} />
      {
        inicio.seo && (
          <>
            {/* Open Graph */}
            <meta property="og:type" content={inicio.seo.open_graph.type} />
            <meta property="og:url" content={inicio.seo.open_graph.url} />
            <meta property="og:title" content={inicio.seo.open_graph.title} />
            <meta property="og:description" content={inicio.seo.open_graph.description} />
            <meta property="og:image" content={inicio.seo.open_graph.image} />

            {/* Twitter */}
            <meta name="twitter:card" content={inicio.seo.twitter.card} />
            <meta name="twitter:url" content={inicio.seo.twitter.url} />
            <meta name="twitter:title" content={inicio.seo.twitter.title} />
            <meta name="twitter:description" content={inicio.seo.twitter.description} />
            <meta name="twitter:image" content={inicio.seo.twitter.image} />
          </>
        )
      }
      <main className="min-h-screen">
        <Routes>
          <Route path="" element={<Inicio inicio={inicio} />} />
          <Route path="cv" element={<Print inicio={inicio} />} />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/**" element={<PageNotFound />} />
        </Routes>
      </main>
      <nav className="sticky bottom-[20px]">
        <Dock />
      </nav>
    </>
  );
}

export default App;
