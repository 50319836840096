import React, { useEffect, useState, useRef } from 'react'

import { FaGithub } from "react-icons/fa";
import { CiGlobe } from "react-icons/ci";
import { Link } from 'react-router-dom';
import Markdown from 'react-markdown';

export default function Cron({ cron, title, subtitle, href }) {

    return (
        <section id="cron">
            <div class="space-y-12 w-full py-12 text-black dark:text-white">
                <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-6px) translateZ(0px)" }}>
                    <div class="flex flex-col items-center justify-center space-y-4 text-center">
                        <div class="space-y-2">
                            <Link className="inline-flex items-center justify-center font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-box_border dark:border-box_border_dark bg-box dark:bg-box_dark  text-foreground shadow-sm hover:bg-accent hover:text-accent-foreground h-8 px-3 text-xs rounded-full" to={href.url}>
                                {/*🎉*/}
                                {href.icono}
                                <div data-orientation="vertical" role="none" className="shrink-0 bg-border w-px mx-2 h-4"></div>{href.texto}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-right ml-1 h-4 w-4 text-subtitle"><path d="m9 18 6-6-6-6"></path></svg>
                            </Link>
                            <h2 class="text-3xl font-bold tracking-tighter sm:text-5xl">{title}</h2>
                            <p class="text-subtitle md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">{subtitle}</p>
                        </div>
                    </div>
                </div>
                <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-6px) translateZ(0px)" }}>
                    <ul class="mb-4 ml-4 divide-y divide-dashed border-l">
                        {
                            cron.map((hackathon, i) => {
                                return (
                                    <CronDetail cron={hackathon} key={i} />
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}


function CronDetail({ cron }) {

    const [isDetailVisible, setDetailVisible] = useState(true);
    const detailRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState('0px'); // altura inicial

    function viewWorkDetail() {
        setDetailVisible(prev => !prev);
    }

    useEffect(() => {
        if (detailRef.current) {
            setMaxHeight(isDetailVisible ? `${detailRef.current.scrollHeight}px` : '00px');
        }
    }, [isDetailVisible]);

    useEffect(() => {
        if (detailRef.current) {
            setMaxHeight(isDetailVisible ? `${detailRef.current.scrollHeight}px` : '00px');
        }
    }, []);

    return (
        <li style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-6px) translateZ(0px)" }}>
            <div class="relative ml-10 py-4 cursor-pointer group" onClick={viewWorkDetail}>
                <div class="absolute -left-16 top-2 flex items-center justify-center bg-white rounded-full">
                    <span class="relative flex shrink-0 overflow-hidden rounded-full border size-12 m-auto">
                        <img class="aspect-square h-full w-full object-contain" alt={cron.nombre} src={cron.imagen} />
                    </span>
                </div>
                <div class="flex flex-1 flex-col justify-start gap-1">
                    <time class="text-xs text-subtitle">{cron.fecha}</time>
                    <div className='flex'>
                        <h3 class="font-semibold leading-none">{cron.nombre}</h3>
                        <span className="inline-flex gap-x-1"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={isDetailVisible ? "lucide lucide-chevron-right size-4 translate-x-0 transform opacity-0 transition-all duration-300 ease-out group-hover:translate-x-1 group-hover:opacity-100 rotate-90" : "lucide lucide-chevron-right size-4 translate-x-0 transform opacity-0 transition-all duration-300 ease-out group-hover:translate-x-1 group-hover:opacity-100 rotate-0"}>
                            <path d="m9 18 6-6-6-6"></path>
                        </svg>
                    </div>
                    <p class="text-sm text-subtitle">{cron.lugar}</p>
                </div>
                <div
                    ref={detailRef}
                    style={{
                        maxHeight,
                        overflow: 'hidden',
                        transition: 'max-height 0.3s ease-in-out' // animación de transición
                    }}
                    className="text-subtitle mt-2 text-xs sm:text-sm markdown"
                >
                    <Markdown>{cron.descripcion}</Markdown>
                </div>
                <div class="mt-2 flex flex-row flex-wrap items-start gap-2">

                    {
                        cron.urls.map((url, i) => {
                            return (
                                <a href={url.url} key={i}>
                                    <div class="items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80 flex gap-2" title="Medium Article">
                                        {
                                            url.type === 'github' ? <FaGithub /> : <CiGlobe />
                                        }
                                        {url.nombre}</div>
                                </a>
                            )
                        })
                    }
                </div>
            </div>
        </li>
    )

}