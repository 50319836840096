import React from 'react'
import Markdown from 'react-markdown'

export default function Print({ inicio }) {
    return (
        <main class="container relative mx-auto scroll-my-12 overflow-auto p-4 print:p-11 md:p-16 bg-transparent" id="main-content">
            <div class="sr-only">
                <h1>{inicio.portfolio_name}</h1>
            </div>
            <section class="mx-auto w-full max-w-2xl space-y-8 bg-transparent print:space-y-4" aria-label="Resume Content">
                <header class="flex items-center justify-between">
                    <div class="flex-1 space-y-1.5">
                        <h1 class="text-2xl font-bold" id="resume-name">{inicio.portfolio_name}</h1>
                        <p class="max-w-md text-pretty font-mono text-sm text-foreground/80 print:text-[12px]" aria-labelledby="resume-name">{inicio.portfolio_subtitle}</p>
                        <p class="max-w-md items-center text-pretty font-mono text-xs text-foreground">
                            {
                                /*
                                <a class="inline-flex gap-x-1.5 align-baseline leading-none hover:underline" href="https://www.google.com/maps/place/Wrocław" target="_blank" rel="noopener noreferrer" aria-label="Location: Wrocław, Poland, CET">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-globe size-3" aria-hidden="true">
                                    <circle cx="12" cy="12" r="10">
                                    </circle>
                                    <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20">
                                    </path>
                                    <path d="M2 12h20">
                                    </path>
                                </svg>Wrocław, Poland, CET</a>
                                */
                            }
                        </p>
                        {/*
                        <div class="flex gap-x-1 pt-1 font-mono text-sm text-foreground/80 print:hidden" role="list" aria-label="Contact links">
                            <a href="https://jarocki.me" aria-label="Personal website" target="_blank" rel="noopener noreferrer" class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground size-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-globe size-4" aria-hidden="true">
                                    <circle cx="12" cy="12" r="10">
                                    </circle>
                                    <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20">
                                    </path>
                                    <path d="M2 12h20">
                                    </path>
                                </svg>
                            </a>
                            <a href="mailto:bartosz.jarocki@hey.com" aria-label="Email" target="_blank" rel="noopener noreferrer" class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground size-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail size-4" aria-hidden="true">
                                    <rect width="20" height="16" x="2" y="4" rx="2">
                                    </rect>
                                    <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7">
                                    </path>
                                </svg>
                            </a>
                            <a href="tel:+48530213401" aria-label="Phone" target="_blank" rel="noopener noreferrer" class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground size-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone size-4" aria-hidden="true">
                                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                    </path>
                                </svg>
                            </a>
                            <a href="https://github.com/BartoszJarocki" aria-label="GitHub" target="_blank" rel="noopener noreferrer" class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground size-8">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="size-4" aria-hidden="true">
                                    <path fill="currentColor" d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12">
                                    </path>
                                </svg>
                            </a>
                            <a href="https://www.linkedin.com/in/bjarocki/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer" class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground size-8">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="size-4" aria-hidden="true">
                                    <title>LinkedIn</title>
                                    <path fill="currentColor" d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z">
                                    </path>
                                </svg>
                            </a>
                            <a href="https://x.com/BartoszJarocki" aria-label="X" target="_blank" rel="noopener noreferrer" class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground size-8">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="size-4" aria-hidden="true">
                                    <title>X</title>
                                    <path fill="currentColor" d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z">
                                    </path>
                                </svg>
                            </a>
                        </div>
                        */}
                        {/*
                        <div class="hidden gap-x-2 font-mono text-sm text-foreground/80 print:flex print:text-[12px]" aria-label="Print contact information">
                            <a class="underline hover:text-foreground/70" href="https://jarocki.me">jarocki.me</a>
                            <span aria-hidden="true">/</span>
                            <a class="underline hover:text-foreground/70" href="mailto:bartosz.jarocki@hey.com">bartosz.jarocki@hey.com</a>
                            <span aria-hidden="true">/</span>
                            <a class="underline hover:text-foreground/70" href="tel:+48530213401">+48530213401</a>
                        </div>
                        */}
                    </div>
                    <span class="relative flex shrink-0 overflow-hidden rounded-xl size-28" aria-hidden="true">
                        <img class="aspect-square h-full w-full" alt="Bartosz Jarocki's profile picture" src={inicio.portfolio_image} />
                    </span>
                </header>
                <div class="space-y-8 print:space-y-4">
                    <section class="flex min-h-0 flex-col gap-y-3 print:gap-y-1">
                        <h2 class="text-xl font-bold" id="about-section">About</h2>
                        <div class="text-pretty font-mono text-sm text-foreground/80 print:text-[12px]" aria-labelledby="about-section"><Markdown>{inicio.portfolio_about}</Markdown></div>
                    </section>
                    <section class="flex min-h-0 flex-col gap-y-3 print:gap-y-1">
                        <h2 class="text-xl font-bold" id="work-experience">Work Experience</h2>
                        <div class="space-y-4 print:space-y-0" role="feed" aria-labelledby="work-experience">
                            {

                                inicio.trabajos.map((work) => {
                                    return (
                                        <article role="article">
                                            <div class="rounded-lg bg-card text-card-foreground py-1 print:py-0">
                                                <div class="flex flex-col space-y-1.5 print:space-y-1">
                                                    <div class="flex items-center justify-between gap-x-2 text-base">
                                                        <h3 class="inline-flex items-center justify-center gap-x-1 font-semibold leading-none print:text-sm">
                                                            <a class="hover:underline" href="" target="_blank" rel="noopener noreferrer" aria-label="">{work.empresa}</a>
                                                            <ul class="list-none p-0 hidden gap-x-1 sm:inline-flex" aria-label="Technologies used">
                                                                {
                                                                    work.tags.map((tag, i) => {
                                                                        return (
                                                                            <li>
                                                                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 align-middle text-xs print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">{tag}</div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </h3>
                                                        <div class="text-sm tabular-nums text-gray-500" aria-label="Employment period: 2024 to Present">{work.desde} {work.hasta}</div>
                                                    </div>
                                                    <h4 class="font-mono text-sm font-semibold leading-none print:text-[12px]">{work.puesto}</h4>
                                                </div>
                                                <div class="text-pretty font-mono text-sm text-muted-foreground">
                                                    <div class="mt-2 text-xs text-foreground/80 print:mt-1 print:text-[10px] text-pretty">Leading technical architecture of a blockchain-based film funding platform.<ul class="list-inside list-disc">
                                                        <li>Architecting migration from CRA to Next.js for improved performance, SEO, and DX</li>
                                                        <li>Established release process enabling faster deployments and reliable rollbacks</li>
                                                        <li>Implementing system-wide monitoring and security improvements</li>
                                                    </ul>
                                                    </div>
                                                    <div class="mt-2">
                                                        <ul class="inline-flex list-none p-0 -mx-2 flex-wrap gap-1 sm:hidden" aria-label="Technologies used">
                                                            <li>
                                                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 align-middle text-xs print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Remote</div>
                                                            </li>
                                                            <li>
                                                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 align-middle text-xs print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">React</div>
                                                            </li>
                                                            <li>
                                                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 align-middle text-xs print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Next.js</div>
                                                            </li>
                                                            <li>
                                                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 align-middle text-xs print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">TypeScript</div>
                                                            </li>
                                                            <li>
                                                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 align-middle text-xs print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Node.js</div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    )
                                })
                            }
                        </div>
                    </section>
                    <section class="flex min-h-0 flex-col gap-y-3 print:gap-y-1">
                        <h2 class="text-xl font-bold" id="education-section">Education</h2>
                        <div class="space-y-4" role="feed" aria-labelledby="education-section">
                            <article role="article">
                                <div class="rounded-lg bg-card text-card-foreground">
                                    <div class="flex flex-col space-y-1.5">
                                        <div class="flex items-center justify-between gap-x-2 text-base">
                                            <h3 class="font-semibold leading-none" id="education-wrocław-university-of-technology">Wrocław University of Technology</h3>
                                            <div class="text-sm tabular-nums text-gray-500" aria-label="Period: 2007 to 2010">2007 2010</div>
                                        </div>
                                    </div>
                                    <div class="text-pretty font-mono text-sm mt-2 text-foreground/80 print:text-[12px]" aria-labelledby="education-wrocław-university-of-technology">Bachelor's Degree in Control systems engineering and Robotics</div>
                                </div>
                            </article>
                        </div>
                    </section>
                    <section class="flex min-h-0 flex-col gap-y-3 print:gap-y-1">
                        <h2 class="text-xl font-bold" id="skills-section">Skills</h2>
                        <ul class="flex list-none flex-wrap gap-1 p-0" aria-label="List of skills">
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: React/Next.js/Remix">React/Next.js/Remix</div>
                            </li>
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: TypeScript">TypeScript</div>
                            </li>
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: Tailwind CSS">Tailwind CSS</div>
                            </li>
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: Design Systems">Design Systems</div>
                            </li>
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: WebRTC">WebRTC</div>
                            </li>
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: WebSockets">WebSockets</div>
                            </li>
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: Node.js">Node.js</div>
                            </li>
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: GraphQL">GraphQL</div>
                            </li>
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: Relay">Relay</div>
                            </li>
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: System Architecture">System Architecture</div>
                            </li>
                            <li>
                                <div class="inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-primary/80 text-primary-foreground hover:bg-primary/60 print:text-[10px]" aria-label="Skill: Remote Team Leadership">Remote Team Leadership</div>
                            </li>
                        </ul>
                    </section>
                    <section class="flex min-h-0 flex-col gap-y-3 print:gap-y-1 print-force-new-page scroll-mb-16 print:space-y-4 print:pt-12">
                        <h2 class="text-xl font-bold" id="side-projects">Side projects</h2>
                        <div class="-mx-3 grid grid-cols-1 gap-3 print:grid-cols-3 print:gap-2 md:grid-cols-2 lg:grid-cols-3" role="feed" aria-labelledby="side-projects">
                            <article class="h-full">
                                <div class="rounded-lg bg-card text-card-foreground flex h-full flex-col overflow-hidden border p-3" role="article">
                                    <div class="flex flex-col space-y-1.5">
                                        <div class="space-y-1">
                                            <h3 class="font-semibold tracking-tight text-base">
                                                <a href="https://monito.dev/" target="_blank" rel="noopener noreferrer" class="inline-flex items-center gap-1 hover:underline" aria-label="Monito project (opens in new tab)">Monito<span class="size-1 rounded-full bg-green-500" aria-label="Active project indicator">

                                                </span>
                                                </a>
                                                <div class="hidden font-mono text-xs underline print:visible" aria-hidden="true">monito.dev</div>
                                            </h3>
                                            <p class="text-foreground text-pretty font-mono text-xs print:text-[10px]" aria-label="Project description">Browser extension for debugging web applications. Includes taking screenshots, screen recording, E2E tests generation and generating bug reports</p>
                                        </div>
                                    </div>
                                    <div class="text-pretty font-mono text-sm text-muted-foreground mt-auto flex">
                                        <ul class="mt-2 flex list-none flex-wrap gap-1 p-0" aria-label="Technologies used">
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">TypeScript</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Next.js</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Browser Extension</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">PostgreSQL</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </article>
                            <article class="h-full">
                                <div class="rounded-lg bg-card text-card-foreground flex h-full flex-col overflow-hidden border p-3" role="article">
                                    <div class="flex flex-col space-y-1.5">
                                        <div class="space-y-1">
                                            <h3 class="font-semibold tracking-tight text-base">
                                                <a href="https://consultly.com/" target="_blank" rel="noopener noreferrer" class="inline-flex items-center gap-1 hover:underline" aria-label="Consultly project (opens in new tab)">Consultly<span class="size-1 rounded-full bg-green-500" aria-label="Active project indicator">

                                                </span>
                                                </a>
                                                <div class="hidden font-mono text-xs underline print:visible" aria-hidden="true">consultly.com</div>
                                            </h3>
                                            <p class="text-foreground text-pretty font-mono text-xs print:text-[10px]" aria-label="Project description">Platform for online consultations with real-time video meetings and scheduling</p>
                                        </div>
                                    </div>
                                    <div class="text-pretty font-mono text-sm text-muted-foreground mt-auto flex">
                                        <ul class="mt-2 flex list-none flex-wrap gap-1 p-0" aria-label="Technologies used">
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">TypeScript</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Next.js</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Vite</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">GraphQL</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">WebRTC</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Tailwind CSS</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">PostgreSQL</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Redis</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </article>
                            <article class="h-full">
                                <div class="rounded-lg bg-card text-card-foreground flex h-full flex-col overflow-hidden border p-3" role="article">
                                    <div class="flex flex-col space-y-1.5">
                                        <div class="space-y-1">
                                            <h3 class="font-semibold tracking-tight text-base">
                                                <a href="https://github.com/BartoszJarocki/cv" target="_blank" rel="noopener noreferrer" class="inline-flex items-center gap-1 hover:underline" aria-label="Minimalist CV project (opens in new tab)">Minimalist CV<span class="size-1 rounded-full bg-green-500" aria-label="Active project indicator">

                                                </span>
                                                </a>
                                                <div class="hidden font-mono text-xs underline print:visible" aria-hidden="true">github.comBartoszJarocki/cv</div>
                                            </h3>
                                            <p class="text-foreground text-pretty font-mono text-xs print:text-[10px]" aria-label="Project description">An open source minimalist, print friendly CV template with a focus on readability and clean design. &gt;9k stars on GitHub</p>
                                        </div>
                                    </div>
                                    <div class="text-pretty font-mono text-sm text-muted-foreground mt-auto flex">
                                        <ul class="mt-2 flex list-none flex-wrap gap-1 p-0" aria-label="Technologies used">
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">TypeScript</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Next.js</div>
                                            </li>
                                            <li>
                                                <div class="inline-flex items-center rounded-md border font-semibold font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/60 px-1 py-0 text-[10px] print:px-1 print:py-0.5 print:text-[8px] print:leading-tight">Tailwind CSS</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </section>
                </div>
            </section>

        </main>
    )
}
